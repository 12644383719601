import {CasMetadata} from 'cas-client-ts';
import {OidcMetadata} from 'oidc-client-ts';

import {User} from './models/User';

// Define default base API URL for application data services.
const defaultApiBaseUrl = 'https://imrsdcliapidev.kaleris.net/api';
// Define a type representing codes for known authentication systems.
type ValidAuthSystem = 'cas' | 'oidc';

// Skip authentication logic and use hardcoded user.
const noAuth = false;

// Maximum active api requests count at time
const maxActiveApiRequestsCount = 2;

// Default hard coded user
const hardcodedUser = {
    ID: 123,
    UserName: 'testUser',
    Stations: [
        {
            ID: 275,
        },
        {
            ID: 137,
        },
        {
            ID: 76,
        },
    ],
    Companies: [{ID: 0, CompanyName: 'System Company'}],
    Company: {
        ID: 2,
    },
    Roles: ['ADMIN', 'EXTERNAL'],
} as User;

// Select a default authentication system to use for logins.
const defaultAuthSystem: ValidAuthSystem = 'cas';

// Api endpoint
const refreshDBIntervalMinutes = 1440;

// defaultResourcesRefetchInterval must be greater than defaultResourcesCacheFallback to avoid the logic that simply returns the cache.
const defaultResourcesRefetchInterval = 180100; // milliseconds
const defaultResourcesCacheFallback = 180000; // milliseconds
const offlineReminderInterval = 14400000; // 4 hours
const offlineCutOff = 604800000; // 7 days

/**
 * Define default URLs and values for authentication.
 * @see {@link CasClientSettings} for further information.
 */
interface ICasAuthConfig {
    authUrl: string;
    clientId: string;
    renew?: boolean;
    metadataUrl?: string;
    metadata?: Partial<CasMetadata>;
    extraRequestHeaders?: HeadersInit;
    extraResponseHeaders?: HeadersInit;
    corsMode?: RequestMode;
}
const defaultCasAuthConfig: ICasAuthConfig = {
    authUrl: 'https://authints.dcli.com/cas/',
    clientId: 'mobile-local',
    renew: false,
    extraRequestHeaders: {
        'Ocp-Apim-Subscription-Key': 'bf8862b4018a4e718bfdff9e51c33fbd',
        'Ocp-Apim-Trace': 'true',
    },
    metadata: {
        login_uri: 'login',
        logout_uri: 'logout',
        validate_uri: 'validate',
        service_validate_uri: 'serviceValidate',
        p3_service_validate_uri: 'p3/serviceValidate',
        proxy_uri: 'proxy',
        proxy_validate_uri: 'proxyValidate',
        p3_proxy_validate_uri: 'p3/proxyValidate',
    },
};

interface IOidcAuthConfig {
    authUrl: string;
    clientId: string;
    metadataUrl?: string;
    metadata?: Partial<OidcMetadata>;
}
const defaultOidcAuthConfig: IOidcAuthConfig = {
    authUrl: 'https://imrs-auth.azurewebsites.net/',
    clientId: 'mobile-local',
};

// Get CAS config from defaults and/or environment.
let casAuthConfig = defaultCasAuthConfig;
if (process.env.REACT_APP_CAS_AUTH_URL) {
    casAuthConfig.authUrl = process.env.REACT_APP_CAS_AUTH_URL;
}
if (process.env.REACT_APP_CAS_CLIENT_ID) {
    casAuthConfig.clientId = process.env.REACT_APP_CAS_CLIENT_ID;
}

// Get OIDC config from defaults and/or environment.
let oidcAuthConfig = defaultOidcAuthConfig;
if (process.env.REACT_APP_AUTH_URL) {
    oidcAuthConfig.authUrl = process.env.REACT_APP_AUTH_URL;
}
if (process.env.REACT_APP_OIDC_CLIENT_ID) {
    oidcAuthConfig.clientId = process.env.REACT_APP_OIDC_CLIENT_ID;
}

// Export authentication config specified by environment variables or defaults.
export default {
    noAuth: noAuth,
    hardcodedUser: hardcodedUser,
    resourcesRefetchInterval: parseInt(process.env.REACT_APP_RESOURCES_REFETCH_INTERVAL!, 10) || defaultResourcesRefetchInterval,
    resourceCacheFallback: parseInt(process.env.REACT_APP_RESOURCES_CACHE_FALLBACK!, 10) || defaultResourcesCacheFallback,
    refreshDBIntervalMinutes,
    offlineReminderInterval,
    offlineCutOff,
    authSystem: process.env.REACT_APP_AUTH_SYSTEM || defaultAuthSystem,
    baseUrl: process.env.REACT_APP_API_ROOT || defaultApiBaseUrl,
    casBaseUrl: process.env.REACT_APP_CAS_BASE_ADDRESS,
    appServiceUrl: process.env.REACT_APP_SERVICE_URL,
    casAuthConfig: casAuthConfig,
    defaultAuthSystem: defaultAuthSystem,
    oidcAuthConfig: oidcAuthConfig,
    maxActiveApiRequestsCount,
};
