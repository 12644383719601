import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {Button, Checkbox} from '@mui/material';
import {push} from 'connected-react-router';
import React, {useCallback, useContext, useMemo, useState} from 'react';
import {useAbac} from 'react-abac';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import DetailsActionIcons, {buildUrls} from '../../components/details-action-icons/details-action-icons.component';
import {OfflineEstimateActionsMenu} from '../../components/offline-estimate-actions-menu/offline-estimate-actions-menu.component';
import OfflineEstimateItemDetails, {buildDetailsUrls} from '../../components/offline-estimate-item-details/offline-estimate-item-details.component';
import useNextOfflineEstimateActions from '../../hooks/use-next-offline-estimate-actions';
import {WorkOrderAction} from '../../hooks/use-next-work-order-action';
import {OfflineEstimateLine} from '../../models/OfflineEstimateLine';
import {OfflineEstimateSentStatus} from '../../models/enumerations/OfflineEstimateSentStatus';
import {Permission} from '../../models/enumerations/Permission';
import {OfflineEstimateDataContext} from '../../providers/offline-estimate-data-provider';
import {queueOfflineEstimates, updateOfflineEstimate} from '../../redux/offline-estimate/offline-estimate.actions';
import {selectOfflineEstimateState} from '../../redux/offline-estimate/offline-estimate.selectors';
import {fetchRepairLinePricing} from '../../redux/pricing/pricing.actions';
import {getUserFriendlySentStatus} from '../../utils/getUserFriendlySentStatus';
import {isOfflineEstimateReadOnly} from '../../utils/isOfflineEstimateReadOnly';

import {Container, Title, Text, ApprovalBtn, RepairLinesSection, CheckboxContainer, TextLeft, TextRight} from './offline-estimate-details.styles';

type OfflineEstimateDetailsProps = {
    path: string;
    title: string;
};

const OfflineEstimateDetails = ({title, path}: OfflineEstimateDetailsProps) => {
    const dispatch = useDispatch();
    const {offlineEstimate} = useContext(OfflineEstimateDataContext);
    const {uploading, polling} = useSelector(selectOfflineEstimateState);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const openMenu = () => setIsMenuOpen(true);
    const closeMenu = () => setIsMenuOpen(false);
    const {t} = useTranslation();
    const {userHasPermissions} = useAbac();
    const showGeneratePOCheckbox = userHasPermissions(Permission.WORK_ORDER_GENERATE_PURCHASE_ORDER_CHECKBOX);
    const readOnly = isOfflineEstimateReadOnly(offlineEstimate?.SentStatus);

    const onOfflineEstimateLineDetailsClick = useCallback(
        (offlineEstimateLine: OfflineEstimateLine) => {
            dispatch(push(`${path}/${offlineEstimate.ID}/line/${offlineEstimateLine.ID}`));
            dispatch(
                fetchRepairLinePricing({
                    companyID: offlineEstimate.CompanyID,
                    stationID: offlineEstimate.StationID,
                    equipmentGroupID: offlineEstimate.EquipmentGroupID,
                    jobCodeID: offlineEstimateLine.JobCodeId,
                    conditionCodeID: offlineEstimateLine.ConditionCodeId,
                    repairSizeID: offlineEstimateLine.RepairSizeId,
                    suppressError: false,
                }),
            );
        },
        [offlineEstimate, dispatch],
    );

    const goToHeader = useCallback(() => {
        dispatch(push(`${path}/${offlineEstimate.ID}/header`));
        closeMenu();
    }, [dispatch, offlineEstimate]);

    const nextAction: WorkOrderAction = {
        label: t('queue'),
        action: queueOfflineEstimates.bind(null, [offlineEstimate.ID]),
    };
    const actions = useNextOfflineEstimateActions(offlineEstimate!);

    const badges = useMemo(
        () => ({
            files: offlineEstimate.Files.length,
            parts: offlineEstimate.RepairLines.reduce((acc, item) => acc + item.Parts.length, 0),
            comments: offlineEstimate.Comments.length + offlineEstimate.RepairLines.reduce((acc, item) => acc + item.Comments.length, 0),
            authorizedSpecialInstructions: 0,
            unauthorizedSpecialInstructions: 0,
        }),
        [offlineEstimate],
    );

    const {Mate, Lot, Row, Spot, SentStatus} = offlineEstimate;

    return (
        <>
            <Container>
                <Title>{t(`${title}`)}</Title>
                {showGeneratePOCheckbox ? (
                    <CheckboxContainer>
                        {showGeneratePOCheckbox ? (
                            <TextLeft>
                                <Checkbox
                                    color="primary"
                                    style={{paddingTop: '8px'}}
                                    checked={offlineEstimate.GeneratePO}
                                    readOnly={readOnly}
                                    onChange={() =>
                                        dispatch(
                                            updateOfflineEstimate(offlineEstimate.ID, {...offlineEstimate, GeneratePO: !offlineEstimate.GeneratePO}),
                                        )
                                    }
                                />
                                {t('generate_po')}
                            </TextLeft>
                        ) : (
                            ''
                        )}
                        <TextRight>
                            <Checkbox
                                color="primary"
                                style={{paddingTop: '8px'}}
                                checked={offlineEstimate.Priority}
                                readOnly={readOnly}
                                onChange={() =>
                                    dispatch(updateOfflineEstimate(offlineEstimate.ID, {...offlineEstimate, Priority: !offlineEstimate.Priority}))
                                }
                            />
                            {t('priority')}
                        </TextRight>
                    </CheckboxContainer>
                ) : (
                    ''
                )}
                {SentStatus === OfflineEstimateSentStatus.Unsent.ID && nextAction && (
                    <ApprovalBtn
                        onClick={() => dispatch(nextAction.action())}
                        startIcon={<ArrowRightAltIcon />}
                        variant="contained"
                        color="primary"
                        fullWidth>
                        {t(nextAction.label)}
                    </ApprovalBtn>
                )}
                <Text>
                    {t('current_status')}:{' '}
                    {getUserFriendlySentStatus(
                        offlineEstimate.SentStatus,
                        offlineEstimate.Status,
                        offlineEstimate.UploadStartDate,
                        polling,
                        uploading,
                    )}
                </Text>
                <Text>
                    {t('mate')}: {Mate}
                </Text>
                <Text>
                    {t('lot_row_spot')}: {Lot || Row || Spot ? `${Lot}/${Row}/${Spot}` : ''}
                </Text>
                <DetailsActionIcons badges={badges} urls={buildUrls(path, offlineEstimate.ID)} />
                <Button variant="contained" fullWidth color="primary" onClick={openMenu}>
                    {t('actions')}
                </Button>
                <RepairLinesSection>
                    <Title>
                        {t('repair_lines')} {`(${offlineEstimate?.RepairLines.length ?? 0})`}
                    </Title>
                    <OfflineEstimateItemDetails
                        onDetailsClick={onOfflineEstimateLineDetailsClick}
                        detailsUrls={buildDetailsUrls(path, offlineEstimate.ID)}
                    />
                </RepairLinesSection>
            </Container>
            <OfflineEstimateActionsMenu actions={actions} onViewHeaderClick={goToHeader} onClose={closeMenu} open={isMenuOpen} />
        </>
    );
};

export default OfflineEstimateDetails;
