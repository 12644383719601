import {Button} from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
    margin: 20px 25px;
    position: relative;
`;

export const Title = styled.h3`
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
    border-bottom: 1px solid ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.primary.main};
    width: 90%;
    margin: 15px auto;
    padding-bottom: 10px;
`;

export const Text = styled.p`
    font-size: 15px;
`;

export const TextLeft = styled.p`
    font-size: 16px;
    float: left;
    margin: 0 0 8px 0;
`;

export const TextRight = styled.p`
    font-size: 16px;
    float: right;
    margin: 0 9px 8px 0;
`;

export const CheckboxContainer = styled.div`
    display: inline-block;
    width: 100%;
`;

export const ApprovalBtn = styled(Button)`
    svg.MuiSvgIcon-root {
        font-size: 30px;
    }
`;

export const RepairLinesSection = styled.section`
    display: flex;
    flex-direction: column;
`;
