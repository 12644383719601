import {WorkOrder} from '../../models/WorkOrder';
import {AddDeclinationReasonDto} from '../../models/dtos/add-declination-reason.dto';
import {AssignWorkOrderDto} from '../../models/dtos/assign-work-order.dto';
import {CreateWorkOrder} from '../../models/dtos/create-work-order.dto';
import {FetchWorkOrderDto} from '../../models/dtos/fetch-work-order.dto';
import {ReassignWorkOrderShopDto} from '../../models/dtos/reassign-work-order-shop.dto';
import {RejectWorkOrderDto} from '../../models/dtos/reject-work-order.dto';
import {RemoveDeclinationReasonDto} from '../../models/dtos/remove-declination-reason.dto';
import {UpdateWorkOrderStatusDto} from '../../models/dtos/update-work-order-status.dto';
import {UpdateWorkOrderDto} from '../../models/dtos/update-work-order.dto';

import {
    FETCH_WORK_ORDER,
    FETCH_WORK_ORDERS,
    WORK_ORDERS_ERROR,
    DELETE_WORK_ORDER,
    UPDATE_WORK_ORDER,
    WORK_ORDERS_REQUEST,
    WORK_ORDERS_RESPONSE,
    WORK_ORDER_RESPONSE,
    CREATE_WORK_ORDER,
    UPDATE_WORK_ORDER_RESPONSE,
    DELETE_WORK_ORDER_RESPONSE,
    REJECTION_WORK_ORDER,
    WORK_ORDER_COMPLETIONS,
    UPDATE_STATUS_WORK_ORDER,
    WORK_ORDER_NDF,
    WORK_ORDER_ASSIGNMENTS,
    CREATE_WORK_ORDER_RESPONSE,
    WORK_ORDER_REQUEST,
    ADD_WORK_ORDER_DECLINATION_REASON,
    ADD_WORK_ORDER_DECLINATION_REASON_RESPONSE,
    REMOVE_WORK_ORDER_DECLINATION_REASON,
    REMOVE_WORK_ORDER_DECLINATION_REASON_RESPONSE,
    REASSIGN_WORK_ORDER_SHOP,
    UNSELECT_WORK_ORDER,
} from './work-order.types';

export const workOrdersRequest = () => ({
    type: WORK_ORDERS_REQUEST,
});

export const createWorkOrder = (data: CreateWorkOrder) => ({
    type: CREATE_WORK_ORDER,
    payload: data,
});

export const updateWorkOrder = (data: UpdateWorkOrderDto) => ({
    type: UPDATE_WORK_ORDER,
    payload: data,
});

export const deleteWorkOrder = (workOrderID: number) => ({
    type: DELETE_WORK_ORDER,
    payload: {workOrderID},
});

export const workOrdersError = (error: Error | unknown) => ({
    type: WORK_ORDERS_ERROR,
    payload: error,
});

export const clearWorkOrdersError = () => ({
    type: WORK_ORDERS_ERROR,
    payload: null,
});

export const workOrdersResponse = (data: WorkOrder[]) => ({
    type: WORK_ORDERS_RESPONSE,
    payload: data,
});

export const workOrderRequest = (workOrderID: number) => ({
    type: WORK_ORDER_REQUEST,
    payload: {workOrderID},
});

export const workOrderResponse = (data: WorkOrder) => ({
    type: WORK_ORDER_RESPONSE,
    payload: data,
});

export const unselectWorkOrder = () => ({
    type: UNSELECT_WORK_ORDER,
});

export const fetchWorkOrder = (payload: FetchWorkOrderDto) => ({
    type: FETCH_WORK_ORDER,
    payload,
});

export const fetchWorkOrders = (forceFetch?: boolean) => ({
    type: FETCH_WORK_ORDERS,
    payload: forceFetch,
});

export const createWorkOrderResponse = (data: WorkOrder) => ({
    type: CREATE_WORK_ORDER_RESPONSE,
    payload: data,
});

export const updateWorkOrderResponse = (workOrderID: number, data?: WorkOrder, loading?: boolean) => ({
    type: UPDATE_WORK_ORDER_RESPONSE,
    payload: {workOrderID, workOrder: data, loading: loading},
});

export const deleteWorkOrderResponse = (workOrderID: number) => ({
    type: DELETE_WORK_ORDER_RESPONSE,
    payload: {workOrderID},
});

export const rejectWorkOrder = (data: RejectWorkOrderDto) => ({
    type: REJECTION_WORK_ORDER,
    payload: data,
});

export const updateWorkOrderStatus = (data: UpdateWorkOrderStatusDto) => ({
    type: UPDATE_STATUS_WORK_ORDER,
    payload: data,
});

export const completeWorkOrder = (workOrderID: number) => ({
    type: WORK_ORDER_COMPLETIONS,
    payload: {workOrderID},
});

export const ndfWorkOrder = (workOrderID: number) => ({
    type: WORK_ORDER_NDF,
    payload: {workOrderID},
});

export const assignWorkOrder = (data: AssignWorkOrderDto) => ({
    type: WORK_ORDER_ASSIGNMENTS,
    payload: data,
});

export const addWorkOrderDeclinationReason = (data: AddDeclinationReasonDto) => ({
    type: ADD_WORK_ORDER_DECLINATION_REASON,
    payload: data,
});

export const addWorkOrderDeclinationReasonResponse = () => ({
    type: ADD_WORK_ORDER_DECLINATION_REASON_RESPONSE,
});

export const removeWorkOrderDeclinationReason = (data: RemoveDeclinationReasonDto) => ({
    type: REMOVE_WORK_ORDER_DECLINATION_REASON,
    payload: data,
});

export const removeWorkOrderDeclinationReasonResponse = (data: RemoveDeclinationReasonDto) => ({
    type: REMOVE_WORK_ORDER_DECLINATION_REASON_RESPONSE,
    payload: data,
});

export const reassignWorkOrderShop = (data: ReassignWorkOrderShopDto) => ({
    type: REASSIGN_WORK_ORDER_SHOP,
    payload: data,
});
