import {Button} from '@mui/material';
import {FormProps, IChangeEvent} from '@rjsf/core';
import {Form} from '@rjsf/mui';
import {FieldProps, RegistryWidgetsType} from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';

import {useDidUpdate} from '../../hooks/use-did-update';
import {Form as FormModel} from '../../models/Form';
import {OfflineEstimateLine} from '../../models/OfflineEstimateLine';
import {WorkOrderRepairLine} from '../../models/WorkOrderRepairLine';
import {RepairLineFields} from '../../models/enumerations/RepairLineFields';
import {selectJobCodesState} from '../../redux/job-code/job-code.selectors';
import {selectSelectedOfflineEstimate} from '../../redux/offline-estimate/offline-estimate.selectors';
import {selectPricingState} from '../../redux/pricing/pricing.selectors';
import {selectUser, selectUserState} from '../../redux/user/user.selectors';
import {selectSelectedWorkOrder} from '../../redux/work-order/work-order.selectors';
import {fixRepairLineSchema} from '../../utils/fixRepairLineSchema';
import getValidJson from '../../utils/getValidJson';
import {isUserRestricted} from '../../utils/isUserRestricted';
import {validate} from '../../utils/repairLineValidation';

import {ConditionCodeDropDownInput} from './condition-code-drop-down-input.component';
import {JobCodeDropDownInput} from './job-code-drop-down-input.component';
import {LaborHoursInput} from './labor-hours-input.component';
import {OvertimeInput} from './overtime-input.component';
import {UpDownWidget} from './quantity-updown-input.component';
import {Container, PaperWrap} from './repair-line-detail-form.styles';
import {RepairSizeDropDownInput} from './repair-size-drop-down-input.component';
import {StraightTimeDisplay} from './straight-time-display.component';
import {UnitLocationCodeDropDownInput} from './unit-location-code-drop-down-input.component';
import {WhyMadeCodeDropDownInput} from './why-made-code-drop-down-input.component';

type LineType = WorkOrderRepairLine | OfflineEstimateLine;

export type RepairLineDetailFormProps<T = LineType> = {
    initialValues: T;
    onSubmit: (data: T) => void;
    formSchemas: FormModel;
    readOnly?: boolean;
    fetchPricing?: (jobCodeID: number, conditionCodeID: number, repairSizeID?: number, suppressError?: boolean) => void;
    clearPricing?: () => void;
};

let customWidgets: RegistryWidgetsType = {
    updown: UpDownWidget,
    'job-code-drop-down': JobCodeDropDownInput,
    'unit-location-code-drop-down': UnitLocationCodeDropDownInput,
    'why-made-code-drop-down': WhyMadeCodeDropDownInput,
    'condition-code-drop-down': ConditionCodeDropDownInput,
    'labor-hours': LaborHoursInput,
    'straight-time': StraightTimeDisplay,
    overtime: OvertimeInput,
};

export const RepairLineDetailForm = <T extends LineType>({
    initialValues,
    onSubmit,
    formSchemas,
    readOnly,
    fetchPricing,
    clearPricing,
}: RepairLineDetailFormProps<T>) => {
    const {t} = useTranslation();
    const offlineEstimate = useSelector(selectSelectedOfflineEstimate);
    const workOrder = useSelector(selectSelectedWorkOrder);
    const dispatch = useDispatch();
    const {schema, schemaNoSize, uiSchema} = fixRepairLineSchema(
        getValidJson(formSchemas?.Schema),
        getValidJson(formSchemas?.Schema),
        getValidJson(formSchemas?.UISchema),
        isUserRestricted(useSelector(selectUser)?.Roles ?? []),
        !!offlineEstimate,
        customWidgets,
    );
    const {jobCodes, loading} = useSelector(selectJobCodesState);
    const [formData, setFormData] = useState<Partial<T>>({...initialValues});
    const [formDataLoaded, setFormDataLoaded] = useState(false);
    const [enableLiveValidation, setEnableLiveValidation] = useState(false);
    const {error, repairLinePricing: pricing} = useSelector(selectPricingState);
    const [laborHoursManuallyModified, setLaborHoursManuallyModified] = useState(
        +((pricing?.MaxHoursPerRepairLine ?? 0) * (formData?.RepairQuantity ?? 0)).toFixed(3) !== (formData.LaborHours ?? 0),
    );

    useEffect(() => {
        if (schema.properties) {
            setFormData(
                Object.keys(schema.properties).reduce<Partial<T>>(
                    (acc, key) => ({
                        ...acc,
                        [key]: initialValues
                            ? initialValues[key as keyof typeof initialValues]
                            : schema.properties[key]?.hasOwnProperty('default')
                            ? +schema.properties[key].default
                            : null,
                    }),
                    {},
                ),
            );
        }
    }, [initialValues]);

    const {JobCodeId, UnitLocationCodeId, WhyMadeCodeId, ConditionCodeId, RepairSizeId, LaborHours} = formData || {};
    const saveButtonReadOnly =
        readOnly ||
        loading ||
        error ||
        (JobCodeId ?? 0) <= 0 ||
        (UnitLocationCodeId ?? 0) <= 0 ||
        (WhyMadeCodeId ?? 0) <= 0 ||
        (ConditionCodeId ?? 0) <= 0;

    useDidUpdate(() => {
        if (JobCodeId && ConditionCodeId && fetchPricing && formDataLoaded) {
            fetchPricing(JobCodeId, ConditionCodeId, RepairSizeId, !offlineEstimate);
        }

        return () => {
            if (clearPricing) {
                clearPricing();
            }
        };
    }, [dispatch, JobCodeId, ConditionCodeId, RepairSizeId, fetchPricing, clearPricing]);

    useEffect(() => {
        if (LaborHours && LaborHours < 0) {
            setFormData((form) => ({...form, Overtime1: 0, Overtime2: 0, Overtime3: 0}));
        }
    }, [LaborHours, setFormData, formData.RepairQuantity]);

    const submit: FormProps<WorkOrderRepairLine>['onSubmit'] = useCallback(
        ({formData}) => {
            onSubmit(formData);
        },
        [onSubmit],
    );

    // Once job code, why made code, and condition code are all selected,
    // repair size should become visible if the matching WhyMade's WhyMadeCodeBaseRepairLineField is 2, 3, or 4
    const showRepairSize = useMemo(() => {
        if (formData && Object.keys(formData).length) {
            const jobCode = jobCodes.find(({ID}) => formData.JobCodeId === ID);
            const allowedRepairLineFields = [RepairLineFields.SizeList, RepairLineFields.SizeNumeric, RepairLineFields.TireRepair];

            if (jobCode && formData.WhyMadeCodeId && formData.ConditionCodeId) {
                const whyMade = jobCode.WhyMade.filter(({WhyMadeCode}) => formData.WhyMadeCodeId === WhyMadeCode.ID);

                return whyMade?.some(({WhyMadeCodeBaseRepairLineField}) => allowedRepairLineFields.includes(WhyMadeCodeBaseRepairLineField));
            }
        }

        return false;
    }, [jobCodes, formData]);

    if (showRepairSize) {
        customWidgets = {...customWidgets, 'repair-size-drop-down': RepairSizeDropDownInput};
    }

    useDidUpdate(() => {
        // If pricing has changed, that means the job code or condition code has changed.
        // Start automatically adjusting the labor hours again.
        // Unless the form is doing the initial render.
        if (formDataLoaded) {
            setLaborHoursManuallyModified(false);
            setFormData({
                ...formData,
                LaborHours: (pricing?.MaxHoursPerRepairLine ?? 0) * (formData.RepairQuantity ?? 1),
            });
        } else {
            setFormDataLoaded(true);
        }
    }, [JobCodeId, ConditionCodeId, pricing]);

    function handleFormChange(this: FieldProps<WorkOrderRepairLine>, event: IChangeEvent) {
        const check_keys: string[] = ['LaborHours', 'MaterialSubtotal', 'Overtime1', 'Overtime2', 'Overtime3'];
        const data = {...event.formData};

        // Undo the condition code here so the pricing fetch can be skipped until the condition code is automatically or manually selected.
        if (data.hasOwnProperty('JobCodeId') && formData.JobCodeId != null && data.JobCodeId != formData.JobCodeId) {
            data.ConditionCodeId = null;
            data.LaborHours = 0;
        }
        if (data.hasOwnProperty('LaborHours') && data.hasOwnProperty('RepairQuantity')) {
            // If labor hours has been manually modified by the user, stop changing it with the quantity.
            if (formData.LaborHours !== null && data['LaborHours'] !== formData.LaborHours) {
                setLaborHoursManuallyModified(true);
            } else if (!laborHoursManuallyModified) {
                // If labor hours hasn't been manually modified, ensure it's equal to the maximum allowed.
                if (data['RepairQuantity']) {
                    if (formData.JobCodeId && formData.ConditionCodeId && pricing?.MaxHoursPerRepairLine) {
                        // Round to 3 decimal places to fix javascript's weird float math.
                        data['LaborHours'] = (pricing.MaxHoursPerRepairLine * data['RepairQuantity']).toFixed(3);
                    } else if (this?.formData?.RepairQuantity && data['RepairQuantity'] !== this.formData.RepairQuantity) {
                        // If quantity was changed and we don't have pricing, extrapolate from the hours already set.
                        // this.formData is the pre-changed data.
                        data['LaborHours'] = ((data['RepairQuantity'] / this.formData.RepairQuantity) * data['LaborHours']).toFixed(3);
                    } else {
                        data['LaborHours'] = 0;
                    }
                }
            }
        }

        check_keys.forEach((key) => {
            // If there are more than 2 decimal places, floor the value at 2 places.
            // Convert the value to a number to handle null and detect non-null non-numbers,
            // then back to string in order to split it on the decimal,
            // then check the length of the string after the decimal.
            if (!isNaN(+data[key]) && (+data[key]).toString().split('.')[1]?.length > 2) {
                data[key] = +data[key].floor();
            } else if (!isNaN(+data[key])) {
                data[key] = +data[key];
            } else {
                return;
            }
        });
        setFormData(data);
    }

    return (
        <Container>
            <PaperWrap>
                <Form
                    disabled={readOnly}
                    noHtml5Validate
                    key={jobCodes.length}
                    onChange={handleFormChange}
                    formData={formData}
                    formContext={{formData}}
                    schema={showRepairSize ? schema : schemaNoSize}
                    uiSchema={uiSchema}
                    widgets={customWidgets}
                    autoComplete={'off'}
                    onSubmit={submit}
                    showErrorList={false}
                    customValidate={(formData, errors) => validate(formData, errors, offlineEstimate?.RepairLines ?? workOrder!.WorkOrderRepairLines)}
                    liveValidate={enableLiveValidation}
                    validator={validator}>
                    <Button
                        fullWidth
                        size="large"
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={saveButtonReadOnly}
                        onClick={() => setEnableLiveValidation(true)}>
                        {t('save')}
                    </Button>
                </Form>
            </PaperWrap>
        </Container>
    );
};
