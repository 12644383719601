import {RegistryWidgetsType} from '@rjsf/utils';

export const fixRepairLineSchema = (
    schema: Record<string, any>,
    schemaNoSize: Record<string, any>,
    uiSchema: Record<string, any>,
    isUserRestricted: boolean,
    isOfflineEstimate: boolean,
    customWidgets: RegistryWidgetsType,
) => {
    if (schema && Object.keys(schema).length) {
        schema.title = schema.description = schemaNoSize.title = schemaNoSize.description = '';
        delete schemaNoSize.properties.RepairSizeId;

        if (schema.properties.hasOwnProperty('ID') && isOfflineEstimate) {
            // The IDs used for offline estimates are larger than int32 and that breaks the validation.
            delete schema.properties.ID;
            delete schemaNoSize.properties.ID;
        }

        if (isUserRestricted) {
            uiSchema.MaterialSubtotal = {'ui:widget': 'hidden'};
            uiSchema.LaborHours = {'ui:widget': 'hidden'};
            uiSchema.Overtime1 = {'ui:widget': 'hidden'};
            uiSchema.Overtime2 = {'ui:widget': 'hidden'};
            uiSchema.Overtime3 = {'ui:widget': 'hidden'};
            uiSchema.MaterialSubtotal = {'ui:widget': 'hidden'};
        } else {
            // If we are showing labor hours and overtime, we need a calculated read-only straight time field.
            if (schema.properties.hasOwnProperty('LaborHours') && schema.properties.hasOwnProperty('Overtime1')) {
                schema.properties.StraightTime = {format: 'decimal', title: 'Straight Time', type: ['null', 'number']};
                schemaNoSize.properties.StraightTime = {format: 'decimal', title: 'Straight Time', type: ['null', 'number']};
                const laborHoursIndex = uiSchema['ui:order'].indexOf('LaborHours');
                laborHoursIndex !== -1
                    ? uiSchema['ui:order'].splice(laborHoursIndex + 1, 0, 'StraightTime')
                    : uiSchema['ui:order'].push('StraightTime');
                uiSchema.StraightTime = {'ui:options': {decimal: true, minimum: 0}, 'ui:widget': 'straight-time'};
            } else {
                delete customWidgets['straight-time'];
            }
        }
    }

    return {schema, schemaNoSize, uiSchema};
};
