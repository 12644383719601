import {push, replace} from 'connected-react-router';
import {differenceInMilliseconds} from 'date-fns';
import {t} from 'i18next';
import {mergeWith, uniqBy} from 'lodash';
import {DeepPartial} from 'redux';
import {takeLatest, call, put, takeLeading, select, all} from 'redux-saga/effects';

import config from '../../config';
import {OfflineEstimate} from '../../models/OfflineEstimate';
import {ReduxAction} from '../../models/ReduxAction';
import {RejectionReason} from '../../models/RejectionReason';
import {WorkOrder} from '../../models/WorkOrder';
import {AddDeclinationReasonDto} from '../../models/dtos/add-declination-reason.dto';
import {AssignWorkOrderDto} from '../../models/dtos/assign-work-order.dto';
import {CreateWorkOrder} from '../../models/dtos/create-work-order.dto';
import {FetchWorkOrderDto} from '../../models/dtos/fetch-work-order.dto';
import {ReassignWorkOrderShopDto} from '../../models/dtos/reassign-work-order-shop.dto';
import {RejectWorkOrderDto} from '../../models/dtos/reject-work-order.dto';
import {RemoveDeclinationReasonDto} from '../../models/dtos/remove-declination-reason.dto';
import {UpdateWorkOrderStatusDto} from '../../models/dtos/update-work-order-status.dto';
import {UpdateWorkOrderDto} from '../../models/dtos/update-work-order.dto';
import {AssociatedTypes} from '../../models/enumerations/AssociatedTypes';
import {WorkOrderStatus} from '../../models/enumerations/WorkOrderStatus';
import IndexedDB, {IndexedDBKeys} from '../../services/indexedDB';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';
import request, {ApiResponse} from '../../services/request';
import {formatDate} from '../../utils/formatDate';
import getLastUpdateTime from '../../utils/getLastUpdateTime';
import {showErrorMessages} from '../../utils/showErrorMessages';
import {updateWorkOrderInStoreAndIndexedDB} from '../../utils/updateWorkOrderInStoreAndIndexedDB';
import {enqueueSnackbar} from '../notistack/notistack.actions';
import {offlineEstimatesResponse} from '../offline-estimate/offline-estimate.actions';
import {fetchRepairLines} from '../repair-line/repair-line.actions';
import {selectSelectedShop} from '../shop/shop.selectors';

import {
    createWorkOrderResponse,
    deleteWorkOrderResponse,
    updateWorkOrderResponse,
    workOrderRequest,
    workOrderResponse,
    workOrdersError,
    workOrdersRequest,
    workOrdersResponse,
    addWorkOrderDeclinationReasonResponse,
    removeWorkOrderDeclinationReasonResponse,
} from './work-order.actions';
import {WorkOrderState} from './work-order.reducer';
import {selectWorkOrderState} from './work-order.selectors';
import {
    FETCH_WORK_ORDERS,
    FETCH_WORK_ORDER,
    UPDATE_WORK_ORDER,
    DELETE_WORK_ORDER,
    REJECTION_WORK_ORDER,
    UPDATE_STATUS_WORK_ORDER,
    WORK_ORDER_COMPLETIONS,
    WORK_ORDER_NDF,
    CREATE_WORK_ORDER,
    WORK_ORDER_ASSIGNMENTS,
    ADD_WORK_ORDER_DECLINATION_REASON,
    REMOVE_WORK_ORDER_DECLINATION_REASON,
    REASSIGN_WORK_ORDER_SHOP,
} from './work-order.types';

const {resourceCacheFallback} = config;

export function* fetchWorkOrders({payload: forceFetch}: ReduxAction<{forceFetch?: boolean}>) {
    const {StationID, CompanyID} = yield select(selectSelectedShop);

    const lastUpdateTime = getLastUpdateTime(LocalStorageKeys.LAST_WORK_ORDERS_REFRESH_TIME, LocalStorageKeys.LAST_WORK_ORDERS_UPDATE_TIME);

    // Immediately respond with what's in the cache.
    let cachedWorkOrders: WorkOrder[] = yield call(IndexedDB.getMany, IndexedDBKeys.WORK_ORDERS);
    yield put(workOrdersResponse(cachedWorkOrders));

    // On page load or shop change, fetch work orders no matter what the last update time is.
    // Otherwise, if its only been a couple minutes, don't bother fetching.
    if (lastUpdateTime && !forceFetch && differenceInMilliseconds(new Date(), lastUpdateTime) <= resourceCacheFallback) {
        return;
    }

    yield put(workOrdersRequest());

    const requestTime = new Date();

    if (!lastUpdateTime) {
        LocalStorage.set(LocalStorageKeys.LAST_WORK_ORDERS_REFRESH_TIME, requestTime);
    }

    const [{data: workOrders, error}, {data: woIDs, error: woIDsError}]: [ApiResponse<WorkOrder[]>, ApiResponse<number[]>] = yield all([
        call(request, {
            url: '/workorders',
            method: 'get',
            params: {
                stationID: StationID,
                companyID: CompanyID,
                ...(lastUpdateTime && {
                    lastUpdateTime: formatDate({
                        date: lastUpdateTime,
                        ISOFormat: true,
                    }),
                }),
            },
            priority: 'high',
        }),
        call(request, {
            url: '/workorderids',
            method: 'get',
            params: {
                stationID: StationID,
                companyID: CompanyID,
            },
        }),
    ]);

    if (error || woIDsError) {
        yield put(workOrdersError(error?.messages || woIDsError?.messages));

        if (cachedWorkOrders) {
            yield put(workOrdersResponse(cachedWorkOrders));
        }
    }

    if (workOrders && woIDs) {
        // Remove deleted wos and wos with changed shop code from cache.
        cachedWorkOrders = cachedWorkOrders.filter((wo) => woIDs.includes(wo.ID));

        // This endpoint returns WorkOrder but with a subset of the properties set so we want to keep the missing stuff we have cached.
        let combinedIDs = Array.from<number>(new Set(workOrders.map(({ID}: WorkOrder) => +ID).concat(cachedWorkOrders.map(({ID}) => ID))));
        let combinedWorkOrders: WorkOrder[] = [];
        combinedIDs.forEach((id) => {
            const cached = cachedWorkOrders.find(({ID}) => ID === id);
            const fresh: WorkOrder | undefined = workOrders.find(({ID}: WorkOrder) => ID === id);

            // Prefers fresh's value unless it's null or undefined.
            combinedWorkOrders = combinedWorkOrders.concat(mergeWith({}, fresh, cached, (f, c) => (f === null || f === undefined ? c : f)));
        });
        combinedWorkOrders = combinedWorkOrders.filter(
            (wo: WorkOrder) => wo.RepairLocation.ID == StationID && wo.Company.ID == CompanyID && !wo.IsDeleted,
        );

        yield put(workOrdersResponse(combinedWorkOrders));

        yield call(IndexedDB.createMany, IndexedDBKeys.WORK_ORDERS, combinedWorkOrders);
        LocalStorage.set(LocalStorageKeys.LAST_WORK_ORDERS_UPDATE_TIME, requestTime);

        // If the shop has changed, the estimate was deleted, or it shouldn't be visible in mobile, add a message to explain.
        let offlineEstimates: OfflineEstimate[] = yield call(IndexedDB.getMany, IndexedDBKeys.OFFLINE_ESTIMATES);
        combinedIDs = combinedWorkOrders.map(({ID}) => ID);

        const rejectLikeStatus = [
            WorkOrderStatus.ErrorEDI.Status,
            WorkOrderStatus.DoNotRepair.Status,
            WorkOrderStatus.WorkRejected.Status,
            WorkOrderStatus.Canceled.Status,
            WorkOrderStatus.Closed.Status,
            WorkOrderStatus.Expired.Status,
        ];
        let unsentOrRejected = offlineEstimates.filter(
            ({WorkOrderID, Status}) => WorkOrderID == null || Status === undefined || rejectLikeStatus.includes(Status),
        );
        let matching = offlineEstimates.filter(({WorkOrderID}) => WorkOrderID != null && combinedIDs.includes(WorkOrderID));
        let nonMatching = offlineEstimates
            .filter(
                ({WorkOrderID, Status}) =>
                    Status !== undefined && !rejectLikeStatus.includes(Status) && WorkOrderID != null && !combinedIDs.includes(WorkOrderID),
            )
            .map((n) => ({...n, Message: t('not_visible'), Status: -1}));

        // For the matches, update the offline estimate's status.
        matching = matching.map((m) => ({
            ...m,
            Status: combinedWorkOrders.find(({ID}) => ID === m.WorkOrderID)?.Status ?? m.Status,
        }));

        offlineEstimates = uniqBy([...matching, ...nonMatching, ...unsentOrRejected], 'ID');

        // Update the cache and state.
        yield call(IndexedDB.createMany, IndexedDBKeys.OFFLINE_ESTIMATES, offlineEstimates);
        yield put(
            offlineEstimatesResponse(
                offlineEstimates.filter(({StationID: eStationID, CompanyID: eCompanyID}) => eStationID === StationID && eCompanyID === CompanyID),
            ),
        );
    }
}

export function* fetchWorkOrder({payload}: ReduxAction<FetchWorkOrderDto>) {
    yield put(workOrderRequest(payload.workOrderID));

    const {data, error}: ApiResponse<WorkOrder> = yield call(request, {
        url: `/workorders/${payload.workOrderID}`,
        method: 'get',
        priority: 'high',
    });

    if (error) {
        if (window.location.href.includes('estimate')) {
            yield put(replace('/estimates'));
        } else {
            yield put(replace('/work-orders'));
        }
    }

    if (data) {
        yield put(workOrderResponse(data));
        yield call(IndexedDB.updateOne, IndexedDBKeys.WORK_ORDERS, data);
    }

    if (payload.onFinish) {
        payload.onFinish(Boolean(error));
    }
}

function* createWorkOrder({payload}: ReduxAction<CreateWorkOrder>) {
    yield put(workOrdersRequest());

    const {data, error}: ApiResponse<WorkOrder> = yield call(request, {
        data: {},
        url: '/workorders',
        method: 'post',
        params: {
            stationID: payload.stationID,
            companyID: payload.companyID,
            unit: payload.unitINO,
            electiveInspectionTypeID: payload.electiveInspectionType,
            priority: payload.priority,
            generatePO: payload.generatePO,
        },
    });

    if (error) {
        yield put(workOrdersError(error.message));
        yield showErrorMessages(error.messages);
    }

    if (data && data.ID > 0) {
        yield put(createWorkOrderResponse(data));
        yield call(IndexedDB.createOne, IndexedDBKeys.WORK_ORDERS, data);

        yield put(push(`/estimates/${data.ID}`));
    }
}

function* updateWorkOrder({payload}: ReduxAction<UpdateWorkOrderDto>) {
    yield put(workOrdersRequest());

    const wo: WorkOrder = yield call(IndexedDB.getOne, IndexedDBKeys.WORK_ORDERS, payload.ID);
    wo.UnitDetails.BITDueDate = payload.BITDueDate ? formatDate(payload.BITDueDate) : '';
    wo.UnitDetails.FHWADueDate = payload.FHWADueDate ? formatDate(payload.FHWADueDate) : '';
    wo.Mate = payload.Mate = payload.Mate?.toUpperCase() ?? '';
    const data = {...wo, ...payload} as WorkOrder;
    yield updateWorkOrderInStoreAndIndexedDB(data, true);

    // Immediately update the state and store.
    const {data: responseData, error}: ApiResponse<WorkOrder> = yield call(request, {
        url: `/workorders/${payload.ID}`,
        method: 'put',
        data: data,
    });

    if (error) {
        yield put(workOrdersError(error.message));
        yield showErrorMessages(error.messages);
    }

    if (responseData) {
        yield updateWorkOrderInStoreAndIndexedDB(responseData);
    } else {
        yield all([put(updateWorkOrderResponse(payload.ID)), call(IndexedDB.deleteOne, IndexedDBKeys.WORK_ORDERS, payload.ID)]);
    }
}

function* deleteWorkOrder({payload}: ReduxAction<{workOrderID: number}>) {
    yield put(workOrdersRequest());

    const {data, error}: ApiResponse<WorkOrder> = yield call(request, {
        url: `/workorders/${payload.workOrderID}`,
        method: 'delete',
    });

    if (error) {
        yield put(workOrdersError(error.message));
        yield showErrorMessages(error.messages);
    }

    if (data) {
        yield put(deleteWorkOrderResponse(data.ID));
        yield call(IndexedDB.deleteOne, IndexedDBKeys.WORK_ORDERS, data.ID);

        yield put(push('/work-orders'));
    }
}

function* rejectWorkOrder({payload}: ReduxAction<RejectWorkOrderDto>) {
    yield put(workOrdersRequest());

    const {error}: ApiResponse = yield call(request, {
        url: `/workorders/rejection/${payload.workOrderID}`,
        method: 'put',
        data: payload.reason || {},
        params: {
            status: payload.status,
            SendExternalIntegrationMessage: payload.sendExternalIntegrationMessage,
        },
    });

    if (error) {
        yield put(workOrdersError(error.message));
        yield showErrorMessages(error.messages);
    } else {
        const {workOrders}: WorkOrderState = yield select(selectWorkOrderState);

        const workOrder = workOrders.find(({ID}) => ID === payload.workOrderID);

        if (workOrder) {
            const {data} = yield call(request, {
                url: `/workorders/${payload.workOrderID}`,
                method: 'get',
            });

            yield updateWorkOrderInStoreAndIndexedDB(data);
        }
    }
}

function* updateWorkOrderStatus({payload}: ReduxAction<UpdateWorkOrderStatusDto>) {
    yield put(workOrdersRequest());

    const {data, error}: ApiResponse<WorkOrder> = yield call(request, {
        url: `/workorders/status/${payload.workOrderID}`,
        method: 'put',
        params: {
            status: payload.status,
        },
    });

    if (error) {
        yield put(workOrdersError(error.message));
        yield showErrorMessages(error.messages);
    }

    if (data) {
        yield all([put(fetchRepairLines(payload.workOrderID)), updateWorkOrderInStoreAndIndexedDB(data)]);
    }
}

function* completeWorkOrder({payload}: ReduxAction<{workOrderID: number}>) {
    yield put(workOrdersRequest());

    const {data, error}: ApiResponse<WorkOrder> = yield call(request, {
        url: `/workorders/${payload.workOrderID}/completions`,
        method: 'put',
    });

    if (error) {
        yield put(workOrdersError(error.message));
        yield showErrorMessages(error.messages);
    }

    if (data) {
        yield updateWorkOrderInStoreAndIndexedDB(data);
    } else {
        yield call(IndexedDB.deleteOne, IndexedDBKeys.WORK_ORDERS, payload.workOrderID);
    }
}

function* ndfWorkOrder({payload}: ReduxAction<{workOrderID: number}>) {
    yield put(workOrdersRequest());

    const {data, error}: ApiResponse<WorkOrder> = yield call(request, {
        url: `/workorders/ndf/${payload.workOrderID}`,
        method: 'put',
    });

    if (error) {
        yield put(workOrdersError(error.message));
        yield showErrorMessages(error.messages);
    }

    if (data) {
        yield updateWorkOrderInStoreAndIndexedDB(data);
    } else {
        yield call(IndexedDB.deleteOne, IndexedDBKeys.WORK_ORDERS, payload.workOrderID);
    }
}

function* assignWorkOrder({payload}: ReduxAction<AssignWorkOrderDto>) {
    yield put(workOrdersRequest());

    const {data, error}: ApiResponse<WorkOrder> = yield call(request, {
        url: `/workorders/assignments/${payload.workOrderID}`,
        method: 'put',
        params: {
            userID: payload.userID,
        },
    });

    if (error) {
        yield put(workOrdersError(error.message));
        yield showErrorMessages(error.messages);
    }

    if (data) {
        yield updateWorkOrderInStoreAndIndexedDB(data);
    } else {
        yield call(IndexedDB.deleteOne, IndexedDBKeys.WORK_ORDERS, payload.workOrderID);
    }
}

function* addWorkOrderDeclinationReason({payload}: ReduxAction<AddDeclinationReasonDto>) {
    yield put(workOrdersRequest());

    const {error}: ApiResponse<RejectionReason> = yield call(request, {
        url: '/exceptionreasons',
        method: 'post',
        data: payload.rejectionReason,
        params: {
            associatedObjectID: payload.ID,
            associatedTypeID: AssociatedTypes.WorkOrder,
        },
    });

    if (error) {
        yield put(workOrdersError(error.message));
        yield showErrorMessages(error.messages);
    } else {
        yield put(addWorkOrderDeclinationReasonResponse());

        const {workOrders}: WorkOrderState = yield select(selectWorkOrderState);

        const workOrder = workOrders.find(({ID}) => ID === payload.ID);

        if (workOrder) {
            const {data} = yield call(request, {
                url: `/workorders/${payload.ID}`,
                method: 'get',
            });

            yield updateWorkOrderInStoreAndIndexedDB(data);
        }
    }
}

function* removeWorkOrderDeclinationReason({payload}: ReduxAction<RemoveDeclinationReasonDto>) {
    yield put(workOrdersRequest());

    const {error}: ApiResponse<RejectionReason> = yield call(request, {
        url: `/exceptionreasons/${payload.errorMessageID}/${AssociatedTypes.WorkOrder}`,
        method: 'delete',
    });

    if (error) {
        yield put(workOrdersError(error.message));
        yield showErrorMessages(error.messages);
    } else {
        yield put(removeWorkOrderDeclinationReasonResponse(payload));

        const {workOrders}: WorkOrderState = yield select(selectWorkOrderState);

        const workOrder = workOrders.find(({ID}) => ID === payload.ID);

        if (workOrder) {
            const {data} = yield call(request, {
                url: `/workorders/${payload.ID}`,
                method: 'get',
            });

            yield updateWorkOrderInStoreAndIndexedDB(data);
        }
    }
}

function* reassignShop({payload}: ReduxAction<ReassignWorkOrderShopDto>) {
    yield put(workOrdersRequest());

    const wo: WorkOrder = yield call(IndexedDB.getOne, IndexedDBKeys.WORK_ORDERS, payload.workOrderID);

    const body: DeepPartial<WorkOrder> = {
        ...wo,
        Company: {
            ID: payload.companyID,
        },
        RepairLocation: {
            ID: payload.stationID,
        },
    };

    const {data, error}: ApiResponse<WorkOrder> = yield call(request, {
        url: `/workorders/${payload.workOrderID}`,
        method: 'put',
        data: body,
        params: {
            resubmit: true,
        },
    });

    if (error) {
        yield put(workOrdersError(error.message));
        yield showErrorMessages(error.messages);
    } else if (data) {
        yield call(IndexedDB.updateOne, IndexedDBKeys.WORK_ORDERS, data);

        yield put(
            enqueueSnackbar(t('reassign_shop_success'), {
                variant: 'success',
                persist: false,
            }),
        );
        yield put(push(payload.backToPath ?? '/estimates'));
    } else {
        yield call(IndexedDB.deleteOne, IndexedDBKeys.WORK_ORDERS, payload.workOrderID);
    }

    yield put(updateWorkOrderResponse(payload.workOrderID, data));
}

export default function* workOrderRootSaga() {
    yield takeLatest<ReduxAction>(WORK_ORDER_NDF, ndfWorkOrder);
    yield takeLeading<ReduxAction>(FETCH_WORK_ORDERS, fetchWorkOrders);
    yield takeLatest<ReduxAction>(FETCH_WORK_ORDER, fetchWorkOrder);
    yield takeLatest<ReduxAction>(UPDATE_WORK_ORDER, updateWorkOrder);
    yield takeLatest<ReduxAction>(DELETE_WORK_ORDER, deleteWorkOrder);
    yield takeLatest<ReduxAction>(CREATE_WORK_ORDER, createWorkOrder);
    yield takeLatest<ReduxAction>(REJECTION_WORK_ORDER, rejectWorkOrder);
    yield takeLatest<ReduxAction>(REASSIGN_WORK_ORDER_SHOP, reassignShop);
    yield takeLatest<ReduxAction>(WORK_ORDER_ASSIGNMENTS, assignWorkOrder);
    yield takeLatest<ReduxAction>(WORK_ORDER_COMPLETIONS, completeWorkOrder);
    yield takeLatest<ReduxAction>(UPDATE_STATUS_WORK_ORDER, updateWorkOrderStatus);
    yield takeLatest<ReduxAction>(ADD_WORK_ORDER_DECLINATION_REASON, addWorkOrderDeclinationReason);
    yield takeLatest<ReduxAction>(REMOVE_WORK_ORDER_DECLINATION_REASON, removeWorkOrderDeclinationReason);
}
