const unrestrictedRoles = [
    'USER_ADMIN',
    'UNIT_ADMIN',
    'INSPECTOR',
    'MANAGER',
    'AUDITOR',
    'AUDITOR_SUPERVISOR',
    'ADMIN',
    'DOCUMENTER',
    'FIELD_AUDITOR',
    'VENDOR_SUPERVISOR',
];

export const isUserRestricted = (roles: string[]) => {
    return !roles.some((r) => unrestrictedRoles.includes(r));
};
