import {Button} from '@mui/material';
import {FormProps} from '@rjsf/core';
import {Form} from '@rjsf/mui';
import {FormValidation} from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import React, {useCallback, useMemo, useState} from 'react';
import {useAbac} from 'react-abac';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {useHeader} from '../../hooks/use-header';
import {User} from '../../models/User';
import {CreateWorkOrderForm} from '../../models/dtos/create-work-order-form.dto';
import {CreateWorkOrder} from '../../models/dtos/create-work-order.dto';
import {Permission} from '../../models/enumerations/Permission';
import {selectIsOfflineStatus} from '../../redux/application/application.selectors';
import {createOfflineEstimate} from '../../redux/offline-estimate/offline-estimate.actions';
import {selectSelectedShop, selectShops} from '../../redux/shop/shop.selectors';
import {selectUser} from '../../redux/user/user.selectors';
import {createWorkOrder} from '../../redux/work-order/work-order.actions';
import {selectWorkOrderState} from '../../redux/work-order/work-order.selectors';
import LocalStorage, {LocalStorageKeys} from '../../services/local-storage';

import {getSchema, getUiSchema} from './create-estimate.schema';
import {Container, HeaderTitle, Loader, PaperWrap} from './create-estimate.style';
import {SelectShop} from './select-shop.component';

const customWidgets = {
    'select-shop': SelectShop,
};

const EstimateCreate = () => {
    const {loading} = useSelector(selectWorkOrderState);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const shops = useSelector(selectShops);
    const user = useSelector(selectUser) as unknown as User;
    const selectedShop = useSelector(selectSelectedShop);
    const [formData, setFormData] = useState<CreateWorkOrder>();
    const isOfflineMode = useSelector(selectIsOfflineStatus);
    const {userHasPermissions} = useAbac();

    const renderMiddleElement = useCallback(
        () => <HeaderTitle>{isOfflineMode ? t('create_offline_estimate') : t('create_estimate')}</HeaderTitle>,
        [t, isOfflineMode],
    );

    useHeader({
        backToPath: '/',
        middleElement: renderMiddleElement,
    });

    const submit: FormProps<CreateWorkOrderForm>['onSubmit'] = useCallback(
        (data) => {
            const shop = data.formData.shopID ? shops.find(({ID}) => ID === data.formData.shopID) : selectedShop;

            if (shop) {
                if (isOfflineMode) {
                    return dispatch(
                        createOfflineEstimate({
                            unitINO: data.formData.unitINO,
                            electiveInspectionType: data.formData.electiveInspectionType,
                            companyID: shop.CompanyID,
                            stationID: shop.StationID,
                            priority: data.formData.priority,
                            redirect: true,
                            generatePO: userHasPermissions(Permission.WORK_ORDER_GENERATE_PURCHASE_ORDER_CHECKBOX),
                        }),
                    );
                } else {
                    dispatch(
                        createWorkOrder({
                            unitINO: data.formData.unitINO,
                            electiveInspectionType: data.formData.electiveInspectionType,
                            companyID: shop.CompanyID,
                            stationID: shop.StationID,
                            priority: data.formData.priority,
                            generatePO: userHasPermissions(Permission.WORK_ORDER_GENERATE_PURCHASE_ORDER_CHECKBOX),
                        }),
                    );
                }

                LocalStorage.set(LocalStorageKeys.LAST_SELECTED_SHOP, shop.ID);
            }
        },
        [dispatch, selectedShop, shops, isOfflineMode],
    );

    const filteredShops = useMemo(() => {
        return user.Companies.map(({ID}) => ID).includes(0) && user.Roles.includes('ADMIN')
            ? shops.filter(({IsDeleted, Active}) => !IsDeleted && Active)
            : shops.filter(
                  ({StationID, CompanyID, IsDeleted, Active}) =>
                      !IsDeleted &&
                      Active &&
                      (user.Companies.map(({ID}) => ID).includes(0) || user.Companies.map(({ID}) => ID).includes(CompanyID)) &&
                      user.Stations.map(({ID}) => ID).includes(StationID),
              );
    }, [shops]);

    const showSelectShopInput = filteredShops.length > 1;

    const schema = useMemo(() => {
        return getSchema({showSelectShopInput, isOfflineMode});
    }, [showSelectShopInput, isOfflineMode]);

    const uiSchema = useMemo(() => {
        return getUiSchema();
    }, []);

    const customValidator = <T extends Partial<CreateWorkOrderForm>>(formData: T, errors: FormValidation) => {
        if (formData.hasOwnProperty('unitINO') && errors.hasOwnProperty('unitINO')) {
            const unitINORegex = new RegExp('^([A-Z]{1,4})([0-9]{1,6})$');
            if (formData?.unitINO == null) {
                errors.unitINO!.addError(t('unit_required'));
            } else if (unitINORegex.exec(formData.unitINO.toUpperCase())?.length !== 3) {
                // 3 matches should be found if it is correct: the full string, the alpha group, and the number group.
                errors.unitINO!.addError(t('unit_pattern'));
            }
        }

        return errors;
    };

    return (
        <Container>
            <PaperWrap>
                <Form
                    schema={schema}
                    onSubmit={submit}
                    uiSchema={uiSchema}
                    widgets={customWidgets}
                    autoComplete="Off"
                    formData={formData}
                    onChange={({formData}) => setFormData(formData)}
                    validator={validator}
                    customValidate={customValidator}
                    showErrorList={false}>
                    <Button
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={loading || !filteredShops || filteredShops.length === 0}
                        fullWidth>
                        {loading ? <Loader size={26} /> : !filteredShops || filteredShops.length === 0 ? t('user_no_shops') : t('create')}
                    </Button>
                </Form>
            </PaperWrap>
        </Container>
    );
};

export default EstimateCreate;
