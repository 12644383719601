import AddIcon from '@mui/icons-material/Add';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DeleteIcon from '@mui/icons-material/Delete';
import {t} from 'i18next';
import React, {useContext} from 'react';
import {useDispatch} from 'react-redux';

import {FileInformation} from '../../models/FileInformation';
import {DocumentTypeCodes} from '../../models/enumerations/DocumentTypeCodes';
import {WorkOrderDataContext} from '../../providers/work-order-data-provider';
import {deleteFile} from '../../redux/file/file.actions';

import {Count, ViewModalContainer, ImageContent, DeleteWrap, AddButton, Navigation, NavBtn, Input, Label, DocumentType} from './files-page.styles';

interface FilesViewModalProps {
    readOnly?: boolean;
    setViewModal: (value: boolean | ((val: boolean) => boolean)) => void;
    selectedFile: number;
    files: FileInformation[];
    handleFile: (e: React.SyntheticEvent<EventTarget>) => void;
    setSelectedFile: (e: number) => void;
    associatedTypeID: number;
}

const FilesViewModal: React.FC<FilesViewModalProps> = ({
    setViewModal,
    handleFile,
    selectedFile,
    files,
    associatedTypeID,
    setSelectedFile,
    readOnly,
}) => {
    const dispatch = useDispatch();
    const img = files[selectedFile];
    const {workOrder} = useContext(WorkOrderDataContext);

    const handleNav = (val: number) => {
        const index = selectedFile + val;
        if (index >= 0 && index < files.length) {
            setSelectedFile(index);
        }
    };

    const handleDelete = () => {
        dispatch(deleteFile(files[selectedFile].ID, workOrder.ID, associatedTypeID));
        setViewModal(false);
    };

    return (
        <ViewModalContainer>
            <ImageContent>
                {img?.src ? (
                    <img src={URL.createObjectURL(new Blob([img.src], {type: img.FileCategory}))} alt={img.OriginalFileName} />
                ) : (
                    <img src={img?.URL} alt={img?.OriginalFileName} />
                )}
            </ImageContent>
            <DocumentType>{t(DocumentTypeCodes[img?.DocumentTypeCode ?? DocumentTypeCodes.PhotoOfDamage])}</DocumentType>
            {!readOnly && (
                <DeleteWrap onClick={handleDelete}>
                    <DeleteIcon />
                </DeleteWrap>
            )}
            <Navigation>
                <NavBtn onClick={() => handleNav(-1)}>
                    <ArrowLeftIcon />
                </NavBtn>
                <Count>
                    {selectedFile + 1} of {files.length}
                </Count>
                <NavBtn onClick={() => handleNav(1)}>
                    <ArrowRightIcon />
                </NavBtn>
            </Navigation>

            {!readOnly && (
                <AddButton>
                    <Input type="file" id="upload-file" accept="image/*;capture=camera" onChange={handleFile} />
                    <Label htmlFor="upload-file">
                        <AddIcon />
                    </Label>
                </AddButton>
            )}
        </ViewModalContainer>
    );
};

export default FilesViewModal;
