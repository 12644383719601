import {FileInformation} from '../../models/FileInformation';

import {
    FILES_ERROR,
    FILES_REQUEST,
    FILES_RESPONSE,
    CREATE_FILE,
    CREATE_FILE_RESPONSE,
    DELETE_FILE,
    DELETE_FILE_RESPONSE,
    FETCH_FILES,
    FetchFiles,
    CreateFile,
    CLEAR_FILE_STATE,
    FILE_RESPONSE,
} from './file.types';

export const filesError = (error: Error | unknown) => ({
    type: FILES_ERROR,
    payload: error,
});

export const createFile = (data: CreateFile) => ({
    type: CREATE_FILE,
    payload: data,
});

export const filesResponse = (data: FileInformation[]) => ({
    type: FILES_RESPONSE,
    payload: data,
});

export const fileResponse = (data: FileInformation[]) => ({
    type: FILE_RESPONSE,
    payload: data,
});

export const createFileResponse = (data: FileInformation) => ({
    type: CREATE_FILE_RESPONSE,
    payload: data,
});

export const deleteFileResponse = (data: FileInformation[]) => ({
    type: DELETE_FILE_RESPONSE,
    payload: data,
});

export const filesRequest = () => ({
    type: FILES_REQUEST,
});

export const deleteFile = (fileID: number, workOrderID: number, associatedTypeID: number) => ({
    type: DELETE_FILE,
    payload: {fileID, workOrderID, associatedTypeID},
});

export const fetchFiles = (data: FetchFiles) => ({
    type: FETCH_FILES,
    payload: data,
});

export const clearFiles = () => ({
    type: CLEAR_FILE_STATE,
});
